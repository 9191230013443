import { Link } from "gatsby";
import React, { useState } from "react";


const MobileNavigation = (displayState) => {

  const [whoWeAre, setWhoWeAreActive] = useState(false);
  const [solutionsActive, setSolutionsActive] = useState(false);
  const [commitmentsActive, setCommitmentsActive] = useState(false);
  const [ourWorkActive, setOurWorkActive] = useState(false);
  const [careersActive, setCareersActive] = useState(false);

  return (

      <div className="lg:hidden mobile-navigation-container p-4 transition duration-500">
        <nav className="mobile-menu">
                <ul>
                  <li>
                      <Link
                          to="/who-we-are"
                          className="font-bold inline-flex items-center text-gray-800 focus:outline-none transition duration-150 ease-in-out"
                          activeClassName="navigation-active"
                          onClick={(e) => {e.preventDefault(); setWhoWeAreActive(!whoWeAre)}}
                          >
                           Who We Are
                      </Link>
                    {whoWeAre && <ul className="mobile-menu-sub-menu">
                            <li><Link to="/who-we-are/#the-group">The Group</Link></li>
                            <li><Link to="/who-we-are/#vision-mission">Vision &amp; Mission</Link></li>
                            <li><Link to="/who-we-are/#our-core-values">Our Core Values</Link></li>
                            <li><Link to="/who-we-are/#heritage-history">Heritage &amp; History</Link></li>
                            <li><Link to="/who-we-are/#our-people">Leadership</Link></li>
                        </ul>
                    }

                </li>
                <li>
                      <Link
                          to="/solutions-services"
                          className="font-bold inline-flex items-center text-gray-800 focus:outline-none transition duration-150 ease-in-out"
                          activeClassName="navigation-active"
                          onClick={(e) => {e.preventDefault(); setSolutionsActive(!solutionsActive)}}
                          >
                           Solutions &amp; Services
                      </Link>
                    {solutionsActive && <ul className="mobile-menu-sub-menu">
                        <li>
                              <Link
                                  to="/solutions-services/engineering-construction"
                                  activeClassName="navigation-active"
                                  >
                                   Engineering &amp; Construction
                              </Link>
                        </li>
                        <li>
                            <a href="http://www.metrixmep.com" target="_blank">Electro-Mechanical
                                <svg className="h-4 inline-block ml-2 -mt-1 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                  <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                  <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                </svg>
                            </a>
                        </li>
                        <li>
                                <a href="http://www.tecmanindustry.com/" target="_blank">Manufacturing
                                    <svg className="h-4 inline-block ml-2 -mt-1 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                        <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                    </svg>
                                </a>
                        </li>
                        <li>
                               <a href="https://hausman.com/" target="_blank">O&amp;M - Integrated Facility Solutions
                                    <svg className="h-4 inline-block ml-2 -mt-1 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                      <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                      <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                    </svg>
                                </a>
                        </li>
                        <li>
                               <a href="https://www.latoscanaimpianti.it/en/" target="_blank">Oil &amp; Gas
                                    <svg className="h-4 inline-block ml-2 -mt-1 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                      <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                      <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                    </svg>
                                </a>
                        </li>
                        <li>
                              <Link
                                  to="#"
                                  activeClassName="navigation-active"
                                  >
                                  Renewable Energy
                              </Link>
                        </li>
                        <li>
                              <Link
                                  to="#"
                                  activeClassName="navigation-active"
                                  >
                                  Waste Management
                              </Link>
                        </li>
                      </ul>
                    }
                </li>
                <li>
                    <Link
                        to="/commitments"
                        className="font-bold inline-flex items-center text-gray-800 focus:outline-none transition duration-150 ease-in-out"
                        activeClassName="navigation-active"
                        onClick={(e) => {e.preventDefault(); setCommitmentsActive(!commitmentsActive)}}
                    >
                        Commitments
                    </Link>
                    {commitmentsActive && <ul className="mobile-menu-sub-menu">
                            <li><Link to="/commitments#corporatecitizenship">Corporate Citizenship</Link></li>
                            <li><Link to="/commitments#safety&welfare">Safety</Link></li>
                            <li><Link to="/commitments#quality">Quality</Link></li>
                            <li><Link to="/commitments#sustainability">Sustainability</Link></li>
                            <li><Link to="/commitments#leanconstruction:themanway">Lean Construction: The MAN Way</Link></li>
                            <li><Link to="/commitments#developingcareers">Developing Careers</Link></li>
                            <li><Link to="/commitments#diversity">Diversity</Link></li>
                            <li><Link to="/commitments#csr">CSR</Link></li>
                      </ul>
                    }

                </li>
                <li>
                    <Link
                        to="/our-work"
                        className="font-bold inline-flex items-center text-gray-800 focus:outline-none transition duration-150 ease-in-out"
                        activeClassName="navigation-active"
                        onClick={(e) => {e.preventDefault(); setOurWorkActive(!ourWorkActive)}}
                    >
                        Our Work
                    </Link>
                    {ourWorkActive && <ul className="mobile-menu-sub-menu">
                            <li><Link to="/our-work" state={{"preset":"Commercial"}}>Commercial</Link></li>
                            <li><Link to="/our-work" state={{"preset":"Education"}}>Education</Link></li>
                            <li><Link to="/our-work" state={{"preset":"Governmental"}}>Governmental</Link></li>
                            <li><Link to="/our-work" state={{"preset":"Healthcare"}}>Healthcare</Link></li>
                            <li><Link to="/our-work" state={{"preset":"Hospitality"}}>Hospitality</Link></li>
                            <li><Link to="/our-work" state={{"preset":"Industrial"}}>Industrial</Link></li>
                            <li><Link to="/our-work" state={{"preset":"Infrastructure & Transport"}}>Infrastructure &amp; Transport</Link></li>
                            <li><Link to="/our-work" state={{"preset":"Offices"}}>Offices</Link></li>
                            <li><Link to="/our-work" state={{"preset":"Recreational"}}>Recreational</Link></li>
                            <li><Link to="/our-work" state={{"preset":"Residential"}}>Residential</Link></li>

                      </ul>
                    }

                </li>
                <li>
                    <Link
                        to="/careers"
                        className="font-bold inline-flex items-center text-gray-800 focus:outline-none transition duration-150 ease-in-out"
                        activeClassName="navigation-active"
                        onClick={(e) => {e.preventDefault(); setCareersActive(!careersActive)}}
                    >
                        Careers
                    </Link>
                    {careersActive && <ul className="mobile-menu-sub-menu">
                            <li><Link to="/careers#why-man-group">Why MAN Enterprise Group</Link></li>
                            <li><Link to="/careers#our-resources">Our Resources</Link></li>
                            <li><Link to="/careers#working-at-man-enterprise">Working at MAN Enterprise</Link></li>
                            <li><Link to="/careers/professionals">Professionals</Link></li>
                            <li><Link to="/careers/students-recent-grads">Recent Grads</Link></li>
                            <li><Link to="/careers/skilled-craft">Skilled Craft</Link></li>
                            <li><Link to="/careers/students-recent-grads">Students</Link></li>
                            <li> <Link target="_blank" to="http://careers.manenterprise.com/Man/application/hrms/online/index.php/safe_Login_cont/index/man/mcor/1/1">
                                Find your role</Link></li>

                        </ul>
                    }
                </li>
                <li>
                    <Link
                        to="/locations"
                        className="font-bold inline-flex items-center text-gray-800 focus:outline-none transition duration-150 ease-in-out"
                        activeClassName="navigation-active"
                    >
                        Contact
                    </Link>
                </li>




              </ul>
          </nav>
      </div>
 
  );
}


export default MobileNavigation;
